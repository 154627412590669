
import { useEffect} from 'react';
import  "./Loading.css";

import { ToastContainer, toast } from 'react-toastify';


type DemoProps = {
  errorMessage:  string;
  lastErrorMessage: string;
}

function ErrorModal({errorMessage, lastErrorMessage}:DemoProps) {
  useEffect(() => {
    if(lastErrorMessage!= errorMessage){
      switch(errorMessage){
        case "Submission Sent!":
          toast.success(errorMessage);
          break;
        case "Exited Successfully!":
          toast.success(errorMessage);
          break;
        case "Attention, by confirming this action, you will be unsubscribing from the Mega Flare White List and will no longer be able to enter. Proceed if you wish to exit.":
          toast.warning(errorMessage);
          break;
        case "Invalid value":
          toast.warning(errorMessage);
          break;
        default:
          toast.error(errorMessage);
      }
    }
  }, [errorMessage]);

  return (
     <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
  );
}

export default ErrorModal;