import React, {createContext} from 'react'
import {UserCoin, UserToken, Address, Offer, SupportMessage} from './models/types'

export const UserContext = createContext({
  ctxToken: null as UserToken,
  ctxAddress: null as Address,
  ctxError: null as string | null,
  ctxLastError: null as string | null,
  ctxSuccess: null as string | null,
  ctxMessage: null as string | null,
  ctxSignedMessage: null as string | null,
  ctxIsParamCorrect: null as boolean | null,
  ctxIsLoading: true as boolean,
  ctxIsEnabled: null as boolean | null,
  ctxIsWhitelisted: null as boolean | null,
  ctxIsRemoved: null as boolean | null,
  ctxFirstRequestSent: false,
  ctxRequestSent: null as boolean | null,
  ctxRegistrationName: "" as string,
  ctxRegistrationEmail: "" as string,
  ctxRegistrationPhone: "" as string,
  ctxRegistrationText: "" as string,
  ctxRegistrationHash: "" as string,
  ctxMode: 0 as number,
  ctxMessages: null as Array<SupportMessage> | null,
  ctxSignSuccess: null as boolean | null,
  ctxAuthenticated: null as boolean | null,
  ctxSetToken: ()=>null,
  ctxSetAddress: ()=>null,
  ctxSetError: () => null,
  ctxSetLastError: () => null,
  ctxSetMessage: () => null,
  ctxSetRequestSent: () => null,
  ctxSetSignedMessage: () => null,
  ctxSetSuccess:() => null,
  ctxSetIsParamCorrect:() => null,
  ctxSetIsLoading:() => null,
  ctxSetIsEnabled:() => null,
  ctxSetIsWhitelisted:() => null,
  ctxSetIsRemoved:() => null,
  ctxSetFirstRequestSent: () => null,
  ctxSetRegistrationName: () => null,
  ctxSetRegistrationPhone: () => null,
  ctxSetRegistrationEmail: () => null,
  ctxSetRegistrationText: () => null,
  ctxSetRegistrationHash: () => null,
  ctxSetMode:() => null,
  ctxSetMessages: ()=> null,
  ctxSetSignSuccess: () => null,
  ctxSetAuthenticated: () => null,
} as ContextData)

export type ContextData = {
  ctxToken: UserToken,
  ctxAddress: Address,
  ctxError: null | string,
  ctxLastError: null | string,
  ctxSuccess: null | string,
  ctxMessage: null | string,
  ctxSignedMessage: null | string,
  ctxIsParamCorrect: boolean | null,
  ctxRequestSent: boolean | null,
  ctxIsLoading: boolean,
  ctxIsEnabled: boolean | null,
  ctxIsWhitelisted: boolean | null,
  ctxIsRemoved: boolean | null,
  ctxFirstRequestSent: boolean ,
  ctxRegistrationName: string,
  ctxRegistrationEmail: string,
  ctxRegistrationPhone: string,
  ctxRegistrationText: string,
  ctxRegistrationHash: string,
  ctxMode: number,
  ctxSignSuccess: boolean | null,
  ctxAuthenticated: boolean | null,
  ctxMessages: Array<SupportMessage> | null,
  ctxSetToken: React.Dispatch<React.SetStateAction<bigint | null>>,
  ctxSetAddress: React.Dispatch<React.SetStateAction<`0x${string}` | null>>,
  ctxSetError: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetLastError: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetSuccess: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetMessage: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetSignedMessage: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetIsParamCorrect:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetRequestSent:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ctxSetIsEnabled:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetIsWhitelisted:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetIsRemoved:React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetFirstRequestSent: React.Dispatch<React.SetStateAction<boolean>>,
  ctxSetRegistrationName: React.Dispatch<React.SetStateAction<string>>,
  ctxSetRegistrationEmail: React.Dispatch<React.SetStateAction<string>>,
  ctxSetRegistrationPhone: React.Dispatch<React.SetStateAction<string>>,
  ctxSetRegistrationText: React.Dispatch<React.SetStateAction<string>>,
  ctxSetRegistrationHash: React.Dispatch<React.SetStateAction<string>>,
  ctxSetSignSuccess: React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetAuthenticated: React.Dispatch<React.SetStateAction<boolean | null>>,
  ctxSetMode: React.Dispatch<React.SetStateAction<number>>,
  ctxSetMessages: React.Dispatch<React.SetStateAction<Array<SupportMessage> | null>>,

}