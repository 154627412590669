import './App.css';
import React, {useContext, useEffect, useState} from "react";
import {useReadContract, useWalletClient} from "wagmi";
import {Address, SupportMessage} from "./models/types";
import {ToastContainer, toast} from "react-toastify";
import ErrorModal from "./Error";
import useAxios from 'axios-hooks'

import {ConnectKitButton} from "connectkit";
import {UserContext} from "./UserContext";
declare var grecaptcha: any;

function App() {
  const [lastToast, setLastToast] = useState(null as string | null);
  const [lastToastString, setLastToastString] = useState(null as string | null);
  const [recaptchaToken, setRecaptchaToken] = useState(null as string | null)
  const {ctxAddress, ctxSetAddress, ctxIsLoading, ctxSuccess, ctxSetSuccess,
        ctxToken, ctxSetToken, ctxSetIsLoading, ctxError, ctxSetError, 
        ctxLastError, ctxSetLastError, ctxIsParamCorrect, ctxSetAuthenticated,
        ctxRegistrationEmail, ctxRegistrationName, ctxSetRegistrationEmail, ctxSetRegistrationName,
        ctxSetRegistrationPhone, ctxRegistrationPhone,
        ctxSetRegistrationText, ctxRegistrationText,
        ctxSetRegistrationHash, ctxRegistrationHash,
        ctxMode, ctxSetMode, ctxMessages,ctxSetMessages,
        ctxSetFirstRequestSent, ctxFirstRequestSent
      } = useContext(UserContext);
  const {data: walletClient} = useWalletClient();
  const [noRequiredToken, setNoRequiredToken] = useState(null as boolean | null);

  useEffect(() => {
    if(ctxSuccess && ctxSuccess == "true") {
      ctxSetAuthenticated(true);
    }
  }, [ctxSuccess]);
  toast.bind(<ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    limit={1}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
  />)

  useEffect(()=>{
    if (ctxIsLoading){
      (() => {const timer = setTimeout(() => {
        ctxSetIsLoading(false);
      }, 1000);
      return () => clearTimeout(timer);})()
    }
  }, [ctxIsLoading])
  
  const [{ data:formHistory, error: authError, loading: authLoading }, refetchAuth] = useAxios({
    url: 'https://cryptoconferenceapi.produzvideo.com/api/form/' + ctxAddress,
    method: 'get',
  }, 
  {manual: true});
  if (!!formHistory && !authError && ctxSuccess != formHistory.success && !authLoading && ctxAddress != null && !ctxFirstRequestSent) {
    var ctxMessagesIds = [] as Array<string>;
    if(ctxMessages != null && ctxMessages.length > 0){
      ctxMessagesIds = ctxMessages.map(m => m.id);
    }
    ctxSetFirstRequestSent(true);
    ctxSetMessages((ctxMessages ?? []).concat(formHistory.data.filter((d: SupportMessage) => !ctxMessagesIds.includes(d.id)) as Array<SupportMessage>) )
    if(ctxIsLoading){
      ctxSetIsLoading(false);
    }
  }
  if (!formHistory && !!authError && !authLoading && ctxError != "Submission History Request Failed" && !ctxFirstRequestSent && ctxAddress != null && ctxLastError != ctxError) {
    ctxSetError("Submission History Request Failed");
    if(ctxIsLoading){
      ctxSetIsLoading(false);
    }
  }
    useEffect(() => {
      if (ctxAddress != null && !ctxFirstRequestSent && formHistory == null){
        refetchAuth();
      }
  }, [ctxAddress]);

  const [{ data:supportContactData, error: supportContactError, loading: supportContactLoading }, refetchSupport] = useAxios({
    url: 'https://cryptoconferenceapi.produzvideo.com/api/form/register',
    method: 'post',
    data: {
      address: ctxAddress,
      name: ctxRegistrationName,
      email: ctxRegistrationEmail,
      phone: ctxRegistrationPhone,
      hash: ctxRegistrationHash,
      description: ctxRegistrationText,
      token: recaptchaToken,
    }
  },
  {manual: true});

  if (ctxMode == 0 && !!supportContactData && !supportContactError && ctxSuccess != supportContactData.success && !supportContactLoading && ctxAddress != null ) {
    if( ctxIsLoading){
      ctxSetIsLoading(false);
    }
    if ((supportContactData?.success ?? "") == "false" && noRequiredToken == null  ) {
      if (ctxError != "Invalid contact"){
        ctxSetSuccess(supportContactData.success.toString());
      }
    } else {
      if(ctxRegistrationText.length > 0){
        ctxSetMode(1)
        var arrays = ctxMessages != null?[...ctxMessages, {
          name: ctxRegistrationName,
          email: ctxRegistrationEmail,
          phone: ctxRegistrationPhone,
          read: false,
          description: ctxRegistrationText,
          hash: ctxRegistrationHash,
          id: "NOW",
          previousMessageId: "",
          audit_RecordCreatedAt: "NOW",
        } as SupportMessage]:[{
          name: ctxRegistrationName,
          email: ctxRegistrationEmail,
          phone: ctxRegistrationPhone,
          read: false,
          description: ctxRegistrationText,
          hash: ctxRegistrationHash,
          id: "NOW",
          previousMessageId: "",
          audit_RecordCreatedAt: "NOW",
        } as SupportMessage];
        ctxSetMessages(arrays)
      }
      ctxSetRegistrationName('');
      ctxSetRegistrationEmail('');
      ctxSetRegistrationPhone('');
      ctxSetRegistrationText('');
      ctxSetRegistrationHash('');
      ctxSetSuccess(supportContactData.success.toString());
      ctxSetError("Submission Sent!");
    }
  }
  if (!supportContactData && !!supportContactError && !supportContactLoading && ctxError != "Support Request Failed" && ctxRegistrationText.length > 0 && ctxAddress != null && ctxLastError != ctxError) {
    ctxSetError("Support Request Failed");
    if( ctxIsLoading){
      ctxSetIsLoading(false);
    }
  }



  if (
    ctxAddress !== walletClient?.account.address.toLowerCase()
  ) {
    if (walletClient?.account.address) {
      ctxSetAddress(walletClient.account.address.toLowerCase() as Address);
    }else {
      ctxSetAddress(null);
    }
  }

  const handleClick = ({ show, isConnected, address }: { show: () => void; isConnected: boolean; address: string | undefined }) => {
    ctxSetAddress(address ? `0x${walletClient?.account.address}` : null);
    ctxSetError("");
    if(show) {
      show()
    }
  };

  useEffect(() => {
    if(recaptchaToken != null){
      refetchSupport().catch(e => {});
    }
  }, [recaptchaToken])
  const submit = () => {
    if (ctxRegistrationText.length > 0){
        grecaptcha.ready(function() {
            grecaptcha.execute('6Led8XAqAAAAAKcKMsRTiUC6-4oGi5LNFsNV0TVd', {action: 'submit'}).then(function(token: string) {
              setRecaptchaToken(token);
        });
      });
      ctxSetIsLoading(true);
      ctxSetError("");

    } else {
      setLastToastString("");
      ctxSetError("No Description");
    }
  };
  if (ctxError) {
    var id = lastToast;
    if (ctxError == "Submission History Request Failed" && ctxError !=  lastToastString && lastToastString != "Submission History Request Failed"){
      id = (toast.error("Submission History Request Failed", {toastId: "Submission History Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Submission History Request Failed");
  
    } else if (ctxError == "No Description" && ctxError !=  lastToastString && lastToastString != "No Description"){
      id = (toast.error("No Description", {toastId: "No Description"})).toString();
      setLastToast(id);
      setLastToastString("No Description");
  
    } else if (ctxError == "Support Request Failed" && ctxError !=  lastToastString && lastToastString != "Support Request Failed"){
      id = (toast.error("Support Request Failed", {toastId: "Support Request Failed"})).toString();
      setLastToast(id);
      setLastToastString("Support Request Failed");
  
    } else if (ctxError == "Api Error" && lastToastString != "Unknown Server Error"){
      id = (toast.error("Unknown Server Error", {toastId: "Unknown Server Error"})).toString();
      setLastToast(id);
      setLastToastString("Unknown Server Error");
    }
  }
  return (
    <>
      <div className='app-body'>
        <div className="effect">
          <img src="/assets/effect.png" alt="vector" />
        </div>

        {ctxIsLoading?<div className="logoIntro">
          <img src="/assets/logoDentroNew.png" alt="logo" />
          <img src="/assets/logoForaNew.png" alt="logo" />
        </div>:
        <main style={{height:(ctxAddress != null?'100%': '160px'), margin:'auto', gap:ctxMode == 1?"30px":"initial", padding:ctxMode == 1?"30px":"initial",justifyContent:ctxMode == 1?"initial!important":"space-around",}}>
          {ctxMode == 0?
          <div className="textMain">
            <h2>Support Form</h2>
            {ctxAddress != null?
            <div className="changeMode" >
            <div className="block"style={{height:'40px'}} onClick={() => {ctxSetMode(1)}}> 
              <svg height="21px" version="1.1" viewBox="0 0 20 21" width="20px" xmlns="http://www.w3.org/2000/svg" ><title/><desc/><defs/><g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#000000" id="Core" opacity="0.9" transform="translate(-464.000000, -254.000000)"><g id="history" transform="translate(464.000000, 254.500000)"><path d="M10.5,0 C7,0 3.9,1.9 2.3,4.8 L0,2.5 L0,9 L6.5,9 L3.7,6.2 C5,3.7 7.5,2 10.5,2 C14.6,2 18,5.4 18,9.5 C18,13.6 14.6,17 10.5,17 C7.2,17 4.5,14.9 3.4,12 L1.3,12 C2.4,16 6.1,19 10.5,19 C15.8,19 20,14.7 20,9.5 C20,4.3 15.7,0 10.5,0 L10.5,0 Z M9,5 L9,10.1 L13.7,12.9 L14.5,11.6 L10.5,9.2 L10.5,5 L9,5 L9,5 Z" id="Shape"/></g></g></g></svg>
              <span style={{marginLeft:'4px'}}>History</span></div>
            </div>:
            <></>}

          </div>
          :
          <div className="textMain">
            <h2>Previous Messages</h2>
            {ctxAddress != null?
            <div className="changeMode" >
            <div className="block"style={{height:'40px'}} onClick={() => {ctxSetMode(0);}}> 
            <svg fill="#000000" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 226.834 226.834" >
            <path d="M80.197,44.939v-9.746c0-1.761,1.433-3.193,3.193-3.193h60.053c1.761,0,3.193,1.433,3.193,3.193v9.746
              c0,1.761-1.433,3.193-3.193,3.193H83.391C81.63,48.133,80.197,46.7,80.197,44.939z M131.841,17c-0.768-9.5-8.729-17-18.424-17
              S95.761,7.5,94.993,17H131.841z M192.309,55.334v151.333c0,11.12-9.047,20.167-20.167,20.167H54.692
              c-11.12,0-20.167-9.047-20.167-20.167V55.334c0-11.12,9.047-20.167,20.167-20.167h10.506c0,0.009-0.001,0.018-0.001,0.026v9.746
              c0,10.032,8.162,18.193,18.193,18.193h60.053c10.032,0,18.193-8.161,18.193-18.193v-9.746c0-0.009-0.001-0.018-0.001-0.026h10.506
              C183.262,35.167,192.309,44.214,192.309,55.334z M88.183,143.449c-3.526-2.173-8.147-1.077-10.32,2.449l-7.092,11.504l-3.661-2.884
              c-3.252-2.563-7.97-2.002-10.532,1.252c-2.563,3.255-2.002,7.97,1.252,10.533l10.271,8.089c1.332,1.049,2.969,1.607,4.64,1.607
              c0.436,0,0.875-0.038,1.311-0.115c2.105-0.374,3.952-1.629,5.074-3.449l11.506-18.666C92.806,150.243,91.709,145.623,88.183,143.449
              z M88.183,89.449c-3.526-2.174-8.147-1.076-10.32,2.449l-7.092,11.504l-3.661-2.884c-3.252-2.562-7.97-2.002-10.532,1.252
              c-2.563,3.255-2.002,7.97,1.252,10.533l10.271,8.089c1.332,1.049,2.969,1.607,4.64,1.607c0.436,0,0.875-0.038,1.311-0.115
              c2.105-0.374,3.952-1.629,5.074-3.449L90.632,99.77C92.806,96.243,91.709,91.623,88.183,89.449z M165.858,168.5
              c0-4.143-3.357-7.5-7.5-7.5h-49c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h49C162.501,176,165.858,172.643,165.858,168.5z
              M165.858,114.5c0-4.143-3.357-7.5-7.5-7.5h-49c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5h49
              C162.501,122,165.858,118.643,165.858,114.5z"/>
            </svg>
              <span style={{marginLeft:'4px'}}>Form</span></div>
            </div>:
            <></>}

          </div>
          }
          {ctxAddress != null?
            ctxMode == 0?
            <>
              <form className="inputContainer">
                <label className="inputLabel">Name</label>
                <input id="name-input" type="text" className="textInputs" value={ctxRegistrationName} onChange={(e) => {
                  const value = e?.target?.value;
                  ctxSetRegistrationName(value);
                }}/>
                <label className="inputLabel">Email</label>
                <input id="email-input" type="email" className="textInputs" value={ctxRegistrationEmail} onChange={(e) => {
                  const value = e?.target?.value;
                  ctxSetRegistrationEmail(value);
                }} />
                <label className="inputLabel">Phone</label>
                <input id="phone-input" type="text" className="textInputs" value={ctxRegistrationPhone} onChange={(e) => {
                  const value = e?.target?.value;
                  ctxSetRegistrationPhone(value);
                }} />
                <label className="inputLabel">Hash</label>
                <input id="phone-input" type="text" className="textInputs" value={ctxRegistrationHash} onChange={(e) => {
                  const value = e?.target?.value;
                  ctxSetRegistrationHash(value);
                }} />
                <label className="inputLabel" >Description</label>
                <textarea id="request-text" className="textInputs" style={{marginBottom: "20px"}} value={ctxRegistrationText} onChange={(e) => {
                  const value = e?.target?.value;
                  ctxSetRegistrationText(value);
                }} />
              </form>
              <ConnectKitButton.Custom>
                {({ show, isConnected, address }) => (
                  <button className="btnConnect" id="show-toast-success" onClick={submit}  >Submit
                </button>)}
              </ConnectKitButton.Custom>
          </>
          :
          <div className='' style={{width:'90%',maxWidth:'400px',minHeight:'40px', borderRadius:'8px', display:'flex', flexDirection:'column', gap: '20px' }}>
            {ctxMessages?.reverse().map((m, i) => <div className='block' key={i} style={{cursor:'default', overflowWrap:'anywhere', width:'90%',maxWidth:'400px',minHeight:'200px',overflowY:'scroll', borderRadius:'8px', display:'flex', flexDirection:'column', backgroundColor: m.read? "#80ff80!important":"#e6fd65"}}> 
                <span style={{margin:'4px'}}>{m.name}</span>
                <span style={{margin:'4px'}}>{m.audit_RecordCreatedAt.slice(0,10)}</span>
                <span style={{margin:'4px'}}>{m.hash}</span> 
                <span style={{margin:'4px'}}>{m.description}</span> 
                <hr></hr>
                {ctxMessages?.filter(c => c.previousMessageId == m.id).map(c => <div className='block' style={{cursor:'default', overflowWrap:'anywhere', width:'90%',maxWidth:'400px',minHeight:'40px', borderRadius:'8px', display:'flex', flexDirection:'column'}}> 
                <span style={{margin:'4px'}}>{c.name}</span>
                <span style={{margin:'4px'}}>{c.audit_RecordCreatedAt.slice(0,10)}</span>
                <span style={{margin:'4px'}}>{c.hash}</span> 
                <span style={{margin:'4px'}}>{c.description}</span> 
                </div>)
                }
              </div>) ??<></>}
          </div>
          :
            <>
                <ConnectKitButton.Custom>
                  {({ show, isConnected, address }) => (
                    <button className="btnConnect" id="show-toast-success" onClick={() => handleClick({ show: show || (() => {}), isConnected, address })}  >Connect
                  </button>)}
                </ConnectKitButton.Custom>
              </>
          }
        </main>
        }
      </div>
        { ctxError != null && ctxError != "" ? <ErrorModal lastErrorMessage={lastToastString ?? ""} errorMessage={ctxError ?? ""} /> : <></>}
      </>
  );
}

export default App;
