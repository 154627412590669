import React, {useContext, useState, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {UserToken, Address, SupportMessage } from './models/types';
import Auth from './Auth';
import Header from './Header';
import {UserContext, ContextData} from './UserContext';
import { Web3Provider } from './Web3Provider';
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer, toast} from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

interface Props {
  children?: ReactNode;
}

const UserProvider = ({children, ...props}: Props) => {
  const [isParamCorrect, setIsParamCorrect] = useState(null as boolean | null); 
  const [loading, setLoading] = useState(true); 
  const [myToken, setMyToken] = useState(null as UserToken);
  const [myAddress, setMyAddress] = useState(null as Address);
  const [myError, setMyError] = useState(null as string | null);
  const [lastError, setLastError] = useState(null as string | null);
  const [mySuccess, setMySuccess] = useState(null as string | null);
  const [isEnabled, setIsEnabled] = useState(null as boolean | null);
  const [isWhitelisted, setWhitelistStatus] = useState(null as boolean | null);
  const [isWhitelistRemoved, setRemovedStatus] = useState(null as boolean | null);
  const [isFirstLoading, setIsFirstLoading] = useState(true as boolean);
  const [requestSent, setRequestSent] = useState(false);
  const [registrationName, setRegistrationName] = useState("" as string);
  const [registrationEmail, setRegistrationEmail] = useState("" as string);
  const [registrationPhone, setRegistrationPhone] = useState("" as string);
  const [registrationHash, setRegistrationHash] = useState("" as string);
  const [mode, setMode] = useState(0 as number);
  const [supportMessages, setSupportMessages] = useState(null as Array<SupportMessage> | null )
  const [registrationText, setRegistrationText] = useState("" as string);
  const [signed, setSigned] = useState(null as boolean | null);
  const [authenticated, setAuthenticated] = useState(null as boolean | null);

  const contextValues = {} as ContextData;
  contextValues.ctxToken = myToken;
  contextValues.ctxAddress = myAddress;
  contextValues.ctxError = myError;
  contextValues.ctxLastError = lastError;
  contextValues.ctxSuccess = mySuccess;
  contextValues.ctxIsLoading = loading;
  contextValues.ctxIsParamCorrect = isParamCorrect;
  contextValues.ctxIsEnabled = isEnabled;
  contextValues.ctxIsWhitelisted = isWhitelisted;
  contextValues.ctxIsRemoved = isWhitelistRemoved;
  contextValues.ctxFirstRequestSent = requestSent;
  contextValues.ctxRegistrationName = registrationName;
  contextValues.ctxRegistrationEmail = registrationEmail;
  contextValues.ctxRegistrationPhone = registrationPhone;
  contextValues.ctxRegistrationHash = registrationHash;
  contextValues.ctxRegistrationText = registrationText;
  contextValues.ctxSignSuccess = signed;
  contextValues.ctxAuthenticated = authenticated;
  contextValues.ctxMode= mode;
  contextValues.ctxMessages= supportMessages;
  contextValues.ctxSetAddress = setMyAddress;
  contextValues.ctxSetToken = setMyToken;
  contextValues.ctxSetError = setMyError;
  contextValues.ctxSetLastError = setLastError;
  contextValues.ctxSetSuccess = setMySuccess;
  contextValues.ctxSetIsLoading = setLoading;
  contextValues.ctxSetIsParamCorrect = setIsParamCorrect;
  contextValues.ctxSetIsEnabled = setIsEnabled;
  contextValues.ctxSetIsWhitelisted = setWhitelistStatus;
  contextValues.ctxSetIsRemoved = setRemovedStatus;
  contextValues.ctxSetFirstRequestSent = setRequestSent;
  contextValues.ctxSetRegistrationName = setRegistrationName;
  contextValues.ctxSetRegistrationEmail = setRegistrationEmail;
  contextValues.ctxSetRegistrationPhone = setRegistrationPhone;
  contextValues.ctxSetRegistrationHash = setRegistrationHash;
  contextValues.ctxSetRegistrationText = setRegistrationText;
  contextValues.ctxSetSignSuccess = setSigned;
  contextValues.ctxSetAuthenticated = setAuthenticated;
  contextValues.ctxSetMode= setMode;
  contextValues.ctxSetMessages= setSupportMessages;

  useEffect(() => {
    if (loading && isFirstLoading){
      const timer = setTimeout(() => {
        setLoading(false);
        setIsFirstLoading(false);
      }, 2000);
      return () => clearTimeout(timer);
    }

  }, [loading]);

  return (
    <UserContext.Provider value={contextValues}>
      {children}
    </UserContext.Provider>
  );
};

const AppWrapper = () => {
  const [pageKey, setPageKey] = useState(null as string | null);
  const {ctxAddress, ctxSetAddress, ctxIsLoading, ctxToken, ctxSetToken, ctxSetSuccess,
        ctxSetIsParamCorrect, ctxSetFirstRequestSent, ctxSetSignSuccess,ctxSetAuthenticated,
        ctxSetIsLoading, ctxError, ctxSetError, ctxLastError, ctxSetLastError, ctxIsParamCorrect, 
        ctxSetIsWhitelisted, ctxSetIsRemoved, ctxSetIsEnabled, ctxIsEnabled, ctxIsWhitelisted, ctxIsRemoved,
        ctxAuthenticated, ctxRegistrationName, ctxRegistrationEmail } = useContext(UserContext);
  const [lastToast, setLastToast] = useState(null as string | null);
  const [lastToastString, setLastToastString] = useState(null as string | null);

  toast.bind(<ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    limit={1}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
  />)

  useEffect(()=>{
    if (ctxAddress == null) {
      ctxSetAddress(null);
      ctxSetToken(null);
      ctxSetError(null);
      ctxSetLastError(null);
      ctxSetSuccess(null);
      ctxSetIsLoading(true);
      ctxSetIsParamCorrect(null);
      ctxSetIsEnabled(null);
      ctxSetIsWhitelisted(null);
      ctxSetIsRemoved(null);
      ctxSetFirstRequestSent(false);
      ctxSetSignSuccess(null);
      ctxSetAuthenticated(null);
    }
  },[ctxAddress])
  useEffect(() => {

    if (ctxError) {
      var id = lastToast;
      switch(ctxError){
        case  "Whitelist is currently closed. " :
          if (ctxError !=  lastToastString && lastToastString != "Whitelist is currently closed. "){
            id = (toast.error("Whitelist is currently closed. ", {toastId: "Whitelist is currently closed. "})).toString();
            setLastToast(id);
            setLastToastString("Whitelist is currently closed. ");
          }
          break;
        case "Registration Request Failed":
            if (ctxError !=  lastToastString && lastToastString != "Registration Request Failed"){
              id = (toast.error("Registration Request Failed", {toastId: "Registration Request Failed"})).toString();
              setLastToast(id);
              setLastToastString("Registration Request Failed");
            } 
          break;
        case "Api Error":
            if (ctxError != lastToastString && lastToastString != "Unknown Server Error"){
              id = (toast.error("Unknown Server Error", {toastId: "Unknown Server Error"})).toString();
              setLastToast(id);
              setLastToastString("Unknown Server Error");
            }
          break;
        case "Submission Sent!":
          if (ctxError !=  lastToastString && lastToastString != "Submission Sent!"){
            setLastToastString("Submission Sent!");
          }
          break;
        case "Exited Successfully!" :
          if (ctxError !=  lastToastString && lastToastString != "Exited Successfully!"){
            id = (toast.success("Exited Successfully!", {toastId: "Exited Successfully!"})).toString();
            setLastToast(id);
            setLastToastString("Exited Successfully!");
          }
          break;
        case "Attention, by confirming this action, you will be unsubscribing from the Mega Flare White List and will no longer be able to enter. Proceed if you wish to exit.":
          if ( ctxError !=  lastToastString && lastToastString != "Attention, by confirming this action, you will be unsubscribing from the Mega Flare White List and will no longer be able to enter. Proceed if you wish to exit."){
            id = (toast.warning("Attention, by confirming this action, you will be unsubscribing from the Mega Flare White List and will no longer be able to enter. Proceed if you wish to exit.", {toastId: "Attention, by confirming this action, you will be unsubscribing from the Mega Flare White List and will no longer be able to enter. Proceed if you wish to exit."})).toString();
            setLastToast(id);
            setLastToastString("Attention, by confirming this action, you will be unsubscribing from the Mega Flare White List and will no longer be able to enter. Proceed if you wish to exit.");
          }
          break;
        case "Invalid value":
          if ( ctxError !=  lastToastString && lastToastString != "Invalid value"){
            id = (toast.warning("Invalid value", {toastId: "Invalid value"})).toString();
            setLastToast(id);
            setLastToastString("Invalid value");
          }
          break;
        case "Exit Failed":
          if (ctxError !=  lastToastString && lastToastString != "Exit Failed"){
            id = (toast.warning("Exit Failed", {toastId: "Exit Failed"})).toString();
            setLastToast(id);
            setLastToastString("Exit Failed");
          }
          break;
        case  "Minimum value is $600.00" :
          if ( ctxError !=  lastToastString && lastToastString != "Minimum value is $600.00"){
            id = (toast.error("Minimum value is $600.00", {toastId: "Minimum value is $600.00"})).toString();
            setLastToast(id);
            setLastToastString("Minimum value is $600.00");
          } 
          break;
        case "No Required Token":
          if ( ctxError !=  lastToastString && lastToastString != "No Required Token"){
            id = (toast.error("No Required Token", {toastId: "No Required Token"})).toString();
            setLastToast(id);
            setLastToastString("No Required Token");
          } 
          break;
        default:
      }
    }
  }, [ctxError])
  return (
    <>
      <Header />
      <Auth/>
    </>
)
}


root.render(
  <>
  <UserProvider>
      <Web3Provider>
        <AppWrapper />
      </Web3Provider>
    </UserProvider>
  </>
  );

